/* Adicionando a fonte OTF */
@font-face {
    font-family: 'AuroBold';
    src: local('AuroBold'), url('./Auro-Bold.otf') format('opentype');
}

/* Adicionando a fonte TTF */
@font-face {
    font-family: 'HindBold';
    src: local('HindBold'), url('./hind-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'HindMedium';
    src: local('HindMedium'), url('./hind-jalandhar-medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Google';
    src: local('Roboto Google'), url('./Roboto/Roboto-VariableFont_wdth\,wght.ttf') format('truetype');
}